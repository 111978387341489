const isProduction = process.env.NODE_ENV === 'production'
const prefix = 'Invariant failed'
export function invariant(condition: any, message?: string | (() => string)): asserts condition {
  if (condition) return
  if (isProduction) throw new Error(prefix)
  const provided: string | undefined = typeof message === 'function' ? message() : message
  throw new Error(provided ? `${prefix}: ${provided}` : prefix)
}

export async function promises<T extends Record<string, any>>(promises: T): Promise<{[K in keyof T]: Awaited<T[K]>}> {
  const entries = Object.entries(promises)
  const values = await Promise.all(
    entries.map(([key, promise]) => Promise.resolve(promise).then((value) => [key, value])),
  )
  return Object.fromEntries(values) as {[K in keyof T]: Awaited<T[K]>}
}

export function assertNever(value: never): never {
  throw new Error(`Unexpected value: ${value}`)
}
